/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import Utils from '../../utils/utils.cy';

export const ids = {
  total: 'total',
  shippingAddressName: 'shipping-address-name-',
  giftCard: 'gift-card',
  addCouponCollapsible: 'add-coupon-collapsible', //
  addGiftCardCollapsible: 'add-gift-card-collapsible', //
  giftCardApplyButton: 'gift-card-applay-button', //
  giftCardDeleteButton: 'gift-card-delete-button', //
  giftCardInput: 'gift-card-input', //
  ourReturnPolicyCollapsible: 'our-return-policy-collapsible', //
  orderSummeryCollapsible: 'order-summery-collapsible', //
  subTotal: 'sub-total', //
  tax: 'tax', //
  delivery: 'delivery', //
  tip: 'tip', //
  title: 'title', //
  noteButton: 'note-button', //
  shippingAddress: 'shipping-address', //
  shippingAddressChangeButton: 'shipping-address-change-button', //
  selectDeliveryDay: 'select-delivery-day', //
  selectDeliveryDayChangeButton: 'select-delivery-day-change-button', //
  selectDeliveryTimeOptionOne: 'select-delivery-time-option-', //
  paymentMethodChangeButton: 'payment-method-change-button', //
  paymentMethodSection: 'payment-method-section', //
  specialRequestSection: 'special-request-section', //
  itemSubstitution: 'item-substitution', //
  toiveling: 'toiveling', //
  tipButton: 'tip-button-number-',
  otherTipButton: 'other-tip-button', //
  otherTipSaveButton: 'other-tip-save-button', //
  otherTipInput: 'other-tip-input', //
  payButton: 'pay-button', //
  addCouponApplyButton: 'add-coupon-applay-button', //
  addCouponInput: 'add-coupon-input', //
  addCouponDeleteButton: 'add-coupon-delete-button', //
  coupon: 'coupon', //
  orderConfirmationTrackOrderButton: 'order-confirmation-track-order-button',
  orderConfirmationKeepShoppingButton:
    'order-confirmation-keep-shopping-button',
  orderConfirmationImage: 'order-confrim-image',
  orderConfirmationOrderNumber: 'order-confirmation-order-number',
  orderConfirmationOrderTotal: 'order-confirmation-order-total',
  orderConfirmationDeliveryTime: 'order-confirmation-order-delivery-time',
  orderConfirmationDeliveryAddress: 'order-confirmation-order-address',
  orderConfirmationOrderMessage: 'order-confirmation-order-message',
  changeAddressButton: 'change-address-button',
};

export default class CheckoutPage {
  get changeAddressButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.changeAddressButton));
  }
  get orderConfirmationOrderMessage() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.orderConfirmationOrderMessage),
    );
  }
  get orderConfirmationDeliveryAddress() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.orderConfirmationDeliveryAddress),
    );
  }
  get orderConfirmationDeliveryTime() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.orderConfirmationDeliveryTime),
    );
  }
  get orderConfirmationOrderTotal() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.orderConfirmationOrderTotal),
    );
  }
  get orderConfirmationOrderNumber() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.orderConfirmationOrderNumber),
    );
  }

  get orderConfirmationImage() {
    return cy.get(Utils.formatDataTestIdSelector(ids.orderConfirmationImage));
  }
  get orderConfirmationTrackOrderButton() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.orderConfirmationTrackOrderButton),
    );
  }
  get orderConfirmationKeepShoppingButton() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.orderConfirmationKeepShoppingButton),
    );
  }
  get coupon() {
    return cy.get(Utils.formatDataTestIdSelector(ids.coupon));
  }

  get giftCardDeleteButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.giftCardDeleteButton));
  }
  get giftCardInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.giftCardInput));
  }
  get giftCardApplyButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.giftCardApplyButton));
  }
  get otherTipSaveButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.otherTipSaveButton));
  }
  get total() {
    return cy.get(Utils.formatDataTestIdSelector(ids.total));
  }
  get orderSummeryCollapsible() {
    return cy.get(Utils.formatDataTestIdSelector(ids.orderSummeryCollapsible));
  }

  get subTotal() {
    return cy.get(Utils.formatDataTestIdSelector(ids.subTotal));
  }

  get tax() {
    return cy.get(Utils.formatDataTestIdSelector(ids.tax));
  }

  get delivery() {
    return cy.get(Utils.formatDataTestIdSelector(ids.delivery));
  }

  get tip() {
    return cy.get(Utils.formatDataTestIdSelector(ids.tip));
  }

  get addCouponCollapsible() {
    return cy.get(Utils.formatDataTestIdSelector(ids.addCouponCollapsible));
  }

  get addGiftCardCollapsible() {
    return cy.get(Utils.formatDataTestIdSelector(ids.addGiftCardCollapsible));
  }

  get ourReturnPolicyCollapsible() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.ourReturnPolicyCollapsible),
    );
  }

  get title() {
    return cy.get(Utils.formatDataTestIdSelector(ids.title));
  }

  get noteButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.noteButton));
  }

  get shippingAddress() {
    return cy.get(Utils.formatDataTestIdSelector(ids.shippingAddress));
  }

  get shippingAddressChangeButton() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.shippingAddressChangeButton),
    );
  }

  get selectDeliveryDay() {
    return cy.get(Utils.formatDataTestIdSelector(ids.selectDeliveryDay));
  }

  get selectDeliveryTimeOptionOne() {
    return cy.get('[data-testid="select-delivery-time-option-0"]');
  }
  get selectDeliveryTimeOptionTwo() {
    return cy.get('[data-testid="select-delivery-time-option-2"]');
  }

  get dialogFooter() {
    return cy.get('[data-testid="dialog-footer"]');
  }

  get paymentMethodChangeButton() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.paymentMethodChangeButton),
    );
  }
  get paymentMethodSection() {
    return cy.get(Utils.formatDataTestIdSelector(ids.paymentMethodSection));
  }

  get specialRequestSection() {
    return cy.get(Utils.formatDataTestIdSelector(ids.specialRequestSection));
  }

  get toiveling() {
    return cy.get(Utils.formatDataTestIdSelector(ids.toiveling));
  }
  get itemSubstitution() {
    return cy.get(Utils.formatDataTestIdSelector(ids.itemSubstitution));
  }

  get tipButtonNumber1() {
    return cy.get(`[data-testid="tip-button-number-0"]`);
  }

  get tipButtonNumber2() {
    return cy.get(`[data-testid="tip-button-number-1"]`);
  }

  get tipButtonNumber3() {
    return cy.get(`[data-testid="tip-button-number-2"]`);
  }

  get tipButtonNumber4() {
    return cy.get(`[data-testid="tip-button-number-3"]`);
  }

  get tipButtonNumber5() {
    return cy.get(`[data-testid="tip-button-number-4"]`);
  }

  get dateCard1() {
    return cy.get(`[data-testid="data-card-number-0"]`);
  }
  get dateCard2() {
    return cy.get(`[data-testid="data-card-number-1"]`);
  }
  get dateCard3() {
    return cy.get(`[data-testid="data-card-number-2"]`);
  }
  get dateCard4() {
    return cy.get(`[data-testid="data-card-number-3"]`);
  }
  get dateCard5() {
    return cy.get(`[data-testid="data-card-number-4"]`);
  }
  get dateCard6() {
    return cy.get(`[data-testid="data-card-number-5"]`);
  }
  get dateCard7() {
    return cy.get(`[data-testid="data-card-number-6"]`);
  }

  get otherTipButton() {
    return cy.get(`[data-testid="chip"]`);
  }

  get otherTipInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.otherTipInput));
  }

  get payButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.payButton));
  }

  get selectDeliveryDayChangeButton() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.selectDeliveryDayChangeButton),
    );
  }
  get giftCard() {
    return cy.get(Utils.formatDataTestIdSelector(ids.giftCard));
  }

  get addCouponApplyButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.addCouponApplyButton));
  }
  get addCouponInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.addCouponInput));
  }
  get addCouponDeleteButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.addCouponDeleteButton));
  }

  get shippingAddressCrownHeight() {
    return cy.get(`[data-testid="shipping-address-name-crown"]`);
  }
  get shippingAddressConneticut() {
    return cy.get(`[data-testid="shipping-address-name-conneticut"]`);
  }
  get shippingAddressOutOfRange() {
    return cy.get(`[data-testid="shipping-address-name-out"]`);
  }

  clickShippingAddressCrownHeight() {
    return this.shippingAddressCrownHeight.click({ force: true });
  }
  clickShippingAddressConneticut() {
    return this.shippingAddressConneticut.click({ force: true });
  }
  clickShippingAddressOutOfRange() {
    return this.shippingAddressOutOfRange.click({ force: true });
  }

  clickOrderConfirmationTrackOrderButton() {
    return this.orderConfirmationTrackOrderButton.click({ force: true });
  }

  clickOrderConfirmationKeepShoppingButton() {
    return this.orderConfirmationKeepShoppingButton.click({ force: true });
  }

  clickAddCouponDeleteButton() {
    return this.addCouponDeleteButton.click({ force: true });
  }

  clickAddCouponApplyButton() {
    return this.addCouponApplyButton.click({ force: true });
  }

  clickOrderSummeryCollapsible() {
    return this.orderSummeryCollapsible.find('button').click({ force: true });
  }

  clickTipButtonNumber3() {
    return this.tipButtonNumber3.click({ force: true });
  }
  clickTipButtonNumber2() {
    return this.tipButtonNumber2.click({ force: true });
  }

  clickAddCouponCollapsible() {
    return this.addCouponCollapsible.find('button').click({ force: true });
  }

  clickChangeAddressButton() {
    return this.changeAddressButton.click({ force: true });
  }
  clickAddGiftCardCollapsible() {
    return this.addGiftCardCollapsible.find('button').click({ force: true });
  }
  clickDateCard3() {
    return this.dateCard3.click({ force: true });
  }

  clickOurReturnPolicyCollapsible() {
    return this.ourReturnPolicyCollapsible
      .find('button')
      .click({ force: true });
  }

  clickNoteButton() {
    return this.noteButton.click({ force: true });
  }

  clickShippingAddressChangeButton() {
    return this.shippingAddressChangeButton.click({ force: true });
  }

  clickSelectDeliveryDay() {
    return this.selectDeliveryDay.click({ force: true });
  }

  clickSelectDeliveryTimeOptionOne() {
    return this.selectDeliveryTimeOptionOne.click({ force: true });
  }
  clickSelectDeliveryTimeOptionTwo() {
    return this.selectDeliveryTimeOptionTwo.click({ force: true });
  }

  clickPaymentMethodChangeButton() {
    return this.paymentMethodChangeButton.click({ force: true });
  }

  clickSpecialRequestSection() {
    return this.specialRequestSection.click({ force: true });
  }

  clickItemSubstitution() {
    return this.itemSubstitution.click({ force: true });
  }

  clickGiftCardApplyButton() {
    return this.giftCardApplyButton.click({ force: true });
  }

  clickOtherTipButton() {
    return this.otherTipButton.click({ force: true });
  }

  clickOtherTipSaveButton() {
    return this.otherTipSaveButton.click({ force: true });
  }

  clickPayButton() {
    return this.payButton.click({ force: true });
  }
  clickGiftCardDeleteButton() {
    return this.giftCardDeleteButton.click({ force: true });
  }

  typeOtherTipInput(text) {
    return this.otherTipInput.type(text, { force: true });
  }

  typeGiftCardInput(text) {
    return this.giftCardInput.type(text, { force: true });
  }
  typeAddCouponInput(text) {
    return this.addCouponInput.type(text, { force: true });
  }
}
