import { Address } from 'teddly-sdk';
import { EmptyState, Button, ButtonVariantEnum } from '@teddly/teddly-ui-components';
import { emptyImageSrc } from '@utils';
import { useRoutes } from './routes';
import { StripeCardData } from '@components/Pages/Checkout/hooks';
import { ICheckoutModel } from 'teddly-sdk/lib/helpers';
import { useTranslation } from 'next-i18next';

const descriptionToTitle = (text: string) => {
  if (!text) {
    return '';
  }
  const words = text?.split(' ');

  for (let i = 0; i < words?.length; i++) {
    words[i] = words[i][0]?.toUpperCase() + words[i].substr(1)?.toLowerCase();
  }

  return words?.join(' ');
};

export const getAddressDescription = (address: ICheckoutModel['shippingAddress']) => {
  return `${descriptionToTitle(address.streetAddress1)}, ${descriptionToTitle(address.city)}, ${address.countryArea}, ${
    address.postalCode
  }`;
};

export const getPaymentDescription = (cardData: StripeCardData) => {
  const card = cardData?.creditCardInfo;
  return `${card?.brand}, **** ${card?.lastDigits}, expires on ${card?.expMonth}/
      ${card?.expYear}`;
};

export const getNodesTableIsNotAvailabilityMessage = ({
  totalCount,
  elementsName,
  notFoundSubTitle,
  notFoundTitle,
  notFoundChildren,
  notFoundIcon,
  notFoundButtonOnClick,
  notFoundButtonOnTitle,
}: {
  totalCount: number;
  elementsName: string;
  notFoundTitle?: string;
  notFoundSubTitle?: string;
  notFoundChildren?: JSX.Element;
  notFoundIcon?: JSX.Element;
  notFoundButtonOnClick?: () => void;
  notFoundButtonOnTitle?: () => void;
}) => {
  const { navigateTo } = useRoutes();
  const { t } = useTranslation('common', { keyPrefix: 'my-orders.orders-empty' });

  const onClick = notFoundButtonOnClick
    ? notFoundButtonOnClick
    : () => {
        navigateTo({ route: '/grocery' });
      };

  return typeof totalCount == 'number' ? (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: '-1',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}>
      <EmptyState
        title={notFoundTitle || t('empty-message', { elements: elementsName })}
        imageSrc={emptyImageSrc}
        subTitle={notFoundSubTitle}
        children={notFoundChildren}
        icon={notFoundIcon}
        btn={
          <Button
            title={notFoundButtonOnTitle || t('continue')}
            onClick={onClick}
            variant={ButtonVariantEnum.OUTLINE}
          />
        }
      />
    </div>
  ) : (
    t('other-msg', { elements: elementsName })
  );
};
