/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import Utils from '../../utils/utils.cy';
import { BasePageObjectModel } from '../../BasePageObjectModel';

export const ids = {
  firstNameInput: 'payment-first-name-input',
  lastNameInput: 'payment-last-name-input',
  cardNumberInput: 'card-number-input',
  expiryDateInput: 'expiry-date-input',
  cvvInput: 'cvv-input',
  billingAddressAsShippingToggle: 'billing-address-as-shipping-toggle',
  addCardButton: 'add-card-button',
};

export default class PaymentMethodFormPage extends BasePageObjectModel {
  url: string;
  title: string;

  constructor() {
    super('/index.php?route=extension/maza/blog/home', 'Blog');
  }

  get firstNameInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.firstNameInput));
  }
  get lastNameInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.lastNameInput));
  }

  get billingAddressAsShippingToggle() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.billingAddressAsShippingToggle)
    );
  }

  get cardNumberInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.cardNumberInput)).find('input');
  }
  get cardExipryDateInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.expiryDateInput)).find('input');
  }
  get cvvInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.cvvInput)).find('input');
  }

  get addCardButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.addCardButton));
  }

  typeLastNameInput(lastName) {
    return this.lastNameInput.type(lastName, { force: true });
  }

  typeFirstNameInput(firstName) {
    return this.firstNameInput.type(firstName, { force: true });
  }

  typeCardNumberInput(cardNumber) {
    return this.cardNumberInput.type(cardNumber, { force: true });
  }
  typeExpiryDateInput(date: string) {
    return this.cardExipryDateInput.type(date, { force: true });
  }

  typeCvvInput(code) {
    return this.cvvInput.type(code, {
      force: true,
    });
  }

  clickAddCardButton() {
    return this.addCardButton.click({ force: true, multiple: true });
  }

  clickBillingAddressAsShippingToggle() {
    return this.billingAddressAsShippingToggle.click({
      force: true,
      multiple: true,
    });
  }

  assertErrorMessage(field, expectedErrorMessage) {
    return field.parents(':lt(2)').next('label').contains(expectedErrorMessage);
  }
}
