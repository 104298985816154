import { useState } from 'react';
import styles from './Delivery.module.scss';
import DeliveryInCheckout from './DeliveryInCheckout';
import { useDeliveryContext } from './context';
import { getNowTimeTitle, getShortSelectedDateDescription } from './utils';
import ShippingAddressStep from '../ShippingAddressStep';
import { Step } from '../..';
import { useCheckoutShippingAddressContext } from '../ShippingAddressStep/context';
import { DeliveryTypeEnum, useAuth } from 'teddly-sdk';
import { usePageLayout } from '@components/Content/PageLayout/context';
import { ids as headerIds } from '../../../../../../cypress/pages/page-layout/HeaderPage.cy';
import { CurrentOpenDialog } from '../../context';

import {
  Package as PackageIcon,
  Plus as PlusIcon,
  Button,
  ButtonVariantEnum,
  ButtonSizeEnum,
  Dialog,
} from '@teddly/teddly-ui-components';
import FastDeliveryIcon from 'public/assets/90-minutes-primary.svg';
import { ids as checkoutIds } from '../../../../../../cypress/pages/checkout/CheckoutPage.cy';
import useWindowDimensions from '@hooks/useWindowDimension';
import AddHomeIcon from 'public/assets/add_home-icon.svg';
import { useCheckoutFlow } from '../../context/checkout-flow';

interface DeliveryInHeaderProps {
  show: boolean;
  showPackageIcon?: boolean;
}

export default function DeliveryInHeader({
  show,
  showPackageIcon = true,
}: DeliveryInHeaderProps) {
  const [isChange, setIsChange] = useState<boolean>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { selectedTimeslot, isTimeslotNeeded, isTimeslotValid } =
    useDeliveryContext();
  const { checkout } = useCheckoutFlow();

  const {
    isCheckoutShippingAddressValid,
    selectedShippingAddress,
    currentOpenDialog,
    selectedShippingMethod,
  } = useCheckoutShippingAddressContext();
  const { user } = useAuth();
  const { openLoginDialog } = usePageLayout();

  const showSelectTimeslotButton =
    !user || !isCheckoutShippingAddressValid || !isTimeslotValid;
  const getDescription = () => {
    if (!isTimeslotNeeded)
      return `${selectedShippingMethod?.name}: As soon as possible`;
    if (
      !isTimeslotValid ||
      !isCheckoutShippingAddressValid ||
      !selectedTimeslot
    )
      return `${selectedShippingMethod?.name}: Select timeslot`;
    if (selectedShippingMethod?.isFastDelivery) {
      return (
        <div className={styles.newTitle}>
          <FastDeliveryIcon />
          <div>{getNowTimeTitle(selectedTimeslot)}</div>
        </div>
      );
    }
    return (
      <span>
        {checkout?.deliveryType == DeliveryTypeEnum.SHIPPING ? (
          <>
            <span>{selectedShippingAddress?.tag?.name}&nbsp;</span>
            <span style={{ color: 'rgba(204, 204, 204, 1)' }}>
              {`(${selectedShippingAddress?.postalCode})`}&nbsp;
            </span>{' '}
          </>
        ) : (
          <span>Pick up time: &nbsp;</span>
        )}
        <span>{`${getShortSelectedDateDescription(selectedTimeslot)}`}</span>
      </span>
    );
    // return `${selectedShippingMethod?.name}: ${getShortSelectedDateDescription(
    //   selectedTimeslot
    // )}`;
  };

  const onCloseHandler = () => {
    setIsDialogOpen(false);
    setIsChange(false);
  };

  const getSelectedAddressIcon = () => {
    const icon = selectedShippingAddress?.tag?.icon;
    if (icon) {
      return <img src={icon?.url} alt={icon?.alt} />;
    }
    return <PackageIcon />;
  };

  const { isLargeTablet } = useWindowDimensions();

  return (
    show && (
      <span className={styles.deliveryInHeader}>
        {showSelectTimeslotButton ? (
          <Button
            variant={ButtonVariantEnum.NEGATIVE}
            size={ButtonSizeEnum.MEDIUM}
            data-testid={headerIds.selectDeliveryTimeSlotButton}
            icon={<AddHomeIcon />}
            title={
              checkout?.deliveryType == DeliveryTypeEnum.PICK_UP
                ? 'Select pickup time'
                : 'Pick delivery slot'
            }
            onClick={() => {
              if (!user) {
                return openLoginDialog();
              }
              setIsDialogOpen(true);
              setIsChange(true);
            }}
          />
        ) : (
          <Button
            variant={ButtonVariantEnum.NEGATIVE}
            titleClassName={styles.selectDeliveryTimeButton}
            data-testid={headerIds.selectDeliveryTimeSlotButton}
            size={ButtonSizeEnum.MEDIUM}
            icon={
              !selectedShippingMethod?.isFastDelivery && showPackageIcon
                ? getSelectedAddressIcon()
                : null
            }
            title={getDescription()}
            onClick={() => {
              setIsDialogOpen(true);
              setIsChange(true);
            }}
          />
        )}
        {
          <Dialog
            onClose={onCloseHandler}
            className={styles.deliveryInHeaderDialog}
            isOpen={
              (isChange && isDialogOpen) ||
              currentOpenDialog === CurrentOpenDialog.PickAddress
            }
            style={{ width: !isLargeTablet ? '740px' : null }}
            title={
              selectedShippingAddress ||
              checkout?.deliveryType == DeliveryTypeEnum.PICK_UP
                ? `Schedule ${
                    checkout?.deliveryType == DeliveryTypeEnum.PICK_UP
                      ? 'Pickup'
                      : 'delivery'
                  }`
                : 'Where to?'
            }>
            <div className={styles.dialogContainer} style={{ width: '100%' }}>
              {checkout?.deliveryType == DeliveryTypeEnum.SHIPPING && (
                <ShippingAddressStep
                  title={Step.SHIPPING_ADDRESS}
                  shippingAddressDataTetsId={checkoutIds.shippingAddress}
                  shippingAddressChangeButtonDataTetsId={
                    checkoutIds.shippingAddressChangeButton
                  }
                />
              )}
              <DeliveryInCheckout
                title={
                  checkout?.deliveryType == DeliveryTypeEnum.PICK_UP
                    ? 'Pickup time'
                    : 'Delivery time'
                }
                onCloseHeaderDialog={onCloseHandler}
              />
            </div>
          </Dialog>
        }
      </span>
    )
  );
}
