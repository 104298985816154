import { Dialog, Button, InputBase, Counter } from '@teddly/teddly-ui-components';
import { useState } from 'react';
import { ids } from '../../../../../cypress/pages/shopping-lists/MyShoppingListsPage.cy';
import { useTranslation } from 'next-i18next';

type CreateListDialogProps = {
  isOpen: boolean;
  closeDialog: () => void;
  submit: (listName: string, quantity?: number) => void;
  listNameForEdit?: string;
  isFromItemDescription?: boolean;
  loading?: boolean;
};

export default function CreateListDialog({
  isOpen,
  closeDialog,
  submit,
  listNameForEdit,
  loading,
  isFromItemDescription,
}: CreateListDialogProps) {
  const [quantity, setQuantity] = useState<number>(1);
  const [listName, setListName] = useState<string>(listNameForEdit ? listNameForEdit : '');
  const { t } = useTranslation('common', { keyPrefix: 'shopping-lists.list-dialog' });

  let isValid: boolean = listName?.length > 2 && listName?.length < 30;

  function onSaveClick() {
    if (isFromItemDescription) {
      submit(listName, quantity);
      setQuantity(1);
    } else {
      submit(listName);
      setListName('');
    }
  }

  return (
    <Dialog
      key={'1'}
      isOpen={isOpen}
      onClose={closeDialog}
      isWithTopMargin
      title={listNameForEdit ? t('title-edit') : t('title-new')}
      footerProps={{
        children: [
          <Button
            key={'2'}
            data-testid={ids.saveListButton}
            disabled={!isValid}
            onClick={onSaveClick}
            title={`Save`}
            loading={loading}
          />,
        ],
      }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <InputBase
          data-testid={ids.shoppingListNameInput}
          name="shoppingListName"
          label={t('label')}
          onChange={(value) => setListName(value.target.value)}
          message={t('failed-msg')}
          defaultValue={listNameForEdit ? listNameForEdit : ''}
        />

        {isFromItemDescription && !listNameForEdit && (
          <div style={{ display: 'flex', gap: '16px', marginTop: '16px', alignItems: 'center', width: '100%' }}>
            Quantity:
            <Counter
              value={quantity}
              disabled={!isFromItemDescription}
              onPlus={() => setQuantity(quantity + 1)}
              onMinus={() => setQuantity(quantity - 1)}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
}
