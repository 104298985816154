import { OrderByIdData } from '@components/Pages/OrderDetailsPage/components/OrderDeliveryDetails';
import { ChannelType } from '@context/channel/ChannelContext';
import moment, { Moment } from 'moment-timezone';
import { LocalStorageItems } from 'teddly-sdk/lib/helpers';

export const getTimezone = (): string => {
  let channel: ChannelType = null;
  const localStringifyChannel = localStorage.getItem(LocalStorageItems.CHANNEL);
  if (localStringifyChannel) {
    channel = JSON.parse(localStringifyChannel) as ChannelType;
  }
  return channel?.timezone || 'America/New_York';
};

type OrderTimeslot = OrderByIdData['timeslot'];

const getTimeslotTime = (date, time) => {
  return moment(`${date}T${time}`);
};

export const MINIMUM_ORDER: number = 25;

export const FAST_DELIVERY_DURATION: number = 90;

export const getDeliveryTimeHandler = (
  isFastDelivery: boolean,
  createdTime: Moment,
  timeslot: OrderTimeslot,
) => {
  // TODO Get fast delivery duration from timeslot
  return isFastDelivery
    ? getFastDeliveryDate(createdTime, timeslot)
    : getFullSelectedDateDescription(timeslot);
};

export const getFastDeliveryDate = (
  created: Moment,
  timeslot: OrderTimeslot,
) => {
  created.add(FAST_DELIVERY_DURATION, 'minutes');
  const timeSlotTime: Moment = getTimeslotStartMoment(timeslot);
  const latestDate: Moment = created.isAfter(timeSlotTime)
    ? created
    : timeSlotTime;
  const now = moment.tz(getTimezone());
  if (isSameDate(latestDate, now)) {
    return `${latestDate.format('[by] LT')}!`;
  }
  return `${latestDate.format('[in] ddd, MMM D, [by] LT')}`;
};

export const validateSelectedTimeslot = (
  selectedTimeslot: OrderTimeslot,
): boolean => {
  const lastOrderTime: Moment = moment(selectedTimeslot.lastTimeToOrder);
  const now: Moment = moment.tz(getTimezone());
  return !(selectedTimeslot && lastOrderTime.isBefore(now));
};

export const getFullSelectedDateDescription = (
  timeslot: OrderTimeslot,
): string => {
  const startTime: Moment = getTimeslotStartMoment(timeslot);
  const endTime: Moment = getTimeslotEndMoment(timeslot);
  return `${startTime.format('ddd, MMM D')}, ${startTime.format('ha')} - ${endTime.format(
    'ha',
  )}`;
};

export const getShortSelectedDateDescription = (
  timeslot: OrderTimeslot,
): string => {
  const startTime: Moment = getTimeslotStartMoment(timeslot);
  const endTime: Moment = getTimeslotEndMoment(timeslot);
  return `${startTime.format('ddd')}, ${startTime.format(
    'ha',
  )} - ${endTime.format('ha')}`;
};

export const getTimeslotStartMoment = (timeslot: OrderTimeslot): Moment => {
  return moment.tz(timeslot?.date, getTimezone());
};

export const getTimeslotEndMoment = (timeslot: OrderTimeslot): Moment => {
  return moment.tz(
    moment(timeslot?.date).format('YYYY-MM-DD') +
      ' ' +
      timeslot?.timeslotMethod.endHour,
    getTimezone(),
  );
};

export const isSameDate = (a: Moment, b: Moment): boolean => {
  return a?.date() === b?.date();
};

export const isSameTimeslot = (a: OrderTimeslot, b: OrderTimeslot): boolean => {
  return a && b && getTimeslotStartMoment(a).isSame(getTimeslotStartMoment(b));
};

export const getNowTimeTitle = (timeslot: OrderTimeslot): string => {
  const timeSlotTime: Moment = getTimeslotStartMoment(timeslot);
  const now = moment.tz(getTimezone());
  if (isSameDate(timeSlotTime, now)) {
    now.add(FAST_DELIVERY_DURATION, 'minutes');
    return now.isAfter(timeSlotTime)
      ? `${now.format('[by] LT')}!`
      : `${timeSlotTime.format('[by] LT')}!`;
  }
  return `${timeSlotTime.format('[on] dddd, [by] LT')}!`;
};
