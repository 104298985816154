import { FC } from 'react';
import { useCartContext } from '@context/cart/cart-provider';
import { Collapsible } from '@teddly/teddly-ui-components';
import Coupon from '../Coupon';
import GiftCard from '../GiftCard';
import { RowWithValue } from './RowWithValue';
import { ids } from '../../../../../../cypress/pages/checkout/CheckoutPage.cy';
import { useTranslation } from 'next-i18next';

const CheckoutInfo: FC = () => {
  const {
    discountPrice,
    shippingPrice,
    subTotalPrice,
    taxPrice,
    total,
    tip,
    giftCards,
  } = useCartContext();
  const { t } = useTranslation('common', { keyPrefix: 'checkout.info' });

  return (
    <>
      <Collapsible
        data-testid={ids.orderSummeryCollapsible}
        title={t('order-summary')}
        initExpand={true}>
        <RowWithValue
          dataTestId={ids.subTotal}
          label={t('summary.sub-total')}
          value={subTotalPrice}
        />
        <RowWithValue
          dataTestId={ids.tax}
          label={t('summary.tax')}
          value={taxPrice}
        />
        <RowWithValue
          dataTestId={ids.delivery}
          label={t('summary.delivery')}
          value={shippingPrice}
        />
        <RowWithValue
          dataTestId={ids.tip}
          label={t('summary.tip')}
          value={tip}
          show={tip > 0}
        />
        <RowWithValue
          dataTestId={ids.giftCard}
          minus
          label={t('summary.gift-card')}
          value={giftCards?.giftCardsUsageAmount?.amount}
          show={giftCards?.cards?.length > 0}
        />
        <RowWithValue
          dataTestId={ids.coupon}
          minus
          label={t('summary.discount')}
          value={discountPrice}
          show={discountPrice > 0}
        />
        <RowWithValue
          dataTestId={ids.total}
          label={t('summary.total')}
          value={total}
          show
        />
      </Collapsible>

      <Collapsible data-testid={ids.addCouponCollapsible} title={t('coupon')}>
        <Coupon />
      </Collapsible>

      <Collapsible
        data-testid={ids.addGiftCardCollapsible}
        title={t('gift-card')}>
        <GiftCard />
      </Collapsible>

      <Collapsible
        data-testid={ids.ourReturnPolicyCollapsible}
        title={t('return-policy')}>
        <div>
          <p>{t('summary.policy-p1')}</p>
          <p>{t('summary.policy-p2')}</p>
          <p>{t('summary.policy-p3')}</p>
          <p>{t('summary.policy-p4')}</p>
        </div>
      </Collapsible>
    </>
  );
};

export default CheckoutInfo;
