import { PaymentGatewayType } from '@app/payment/types';
import { getPaymentDescription } from '@utils/descriptions';
import { deletePaymentSource } from '@app/payment';
import { useState } from 'react';
import MethodsList from './MethodsList';
import { StripeCardData } from '../hooks';

type PaymentMethodsListProps = {
  cards?: StripeCardData[];
  selectedMethodId?: string;
  onSelectCard?: (card) => Promise<void>;
  onDeleteCard?: () => void;
  paymentGateway: PaymentGatewayType;
};

export default function PaymentMethodsList({
  cards = [],
  selectedMethodId,
  onSelectCard,
  onDeleteCard,
  paymentGateway,
  ...otherProps
}: PaymentMethodsListProps) {
  const selectMethodHandler = async (card) => {
    if (onSelectCard) await onSelectCard(card);
  };

  const [deletedCardIds, setDeletedCardIds] = useState<string[]>([]);

  const onDeleteCardHandler = async (id: string) => {
    setDeletedCardIds((prev) => [...prev, id]);
    await deletePaymentSource(paymentGateway, id);

    await onDeleteCard();
    setDeletedCardIds((prev) => {
      prev.filter((e) => e != id);
      return [...prev];
    });
  };

  return (
    <MethodsList
      {...otherProps}
      blockTitle="Payment Method"
      descriptionFormatter={getPaymentDescription}
      methods={cards}
      selectedMethodId={selectedMethodId}
      deletedMethodIds={deletedCardIds}
      onSelectCard={selectMethodHandler}
      onDeleteCard={onDeleteCardHandler}
    />
  );
}
