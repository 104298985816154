import {
  useContext,
  createContext,
  ReactNode,
  useEffect,
  useState,
  useRef,
} from 'react';

import { CountryCode, useAuth, useCheckout } from 'teddly-sdk';
import { SaleorCheckoutAPI } from 'teddly-sdk/lib/api';
import { ICheckoutModel, ICheckoutAddress } from 'teddly-sdk/lib/helpers';

import { updateCheckoutLines } from '@app/checkout';
import { useCartContext } from '@context/cart/cart-provider';
import { useNetworkState } from '@hooks/useNetworkState';
import { PaymentSource } from '@interfaces';

import { getTeddlySdkApi } from '@config/teddly-sdk';
import { getAvailablePaymentGateway } from '@utils/paymentGateways';
import { PaymentGatewayType } from '@app/payment/types';

const CheckoutFlowContext = createContext<Partial<SaleorCheckoutAPI>>(null);

export function CheckoutFlowProvider({ children }: { children: ReactNode }) {
  const checkout = useCheckout();

  return (
    <CheckoutFlowContext.Provider value={{ ...checkout }}>
      {children}
    </CheckoutFlowContext.Provider>
  );
}

export function useCheckoutFlow() {
  const context = useContext(CheckoutFlowContext);
  if (!context)
    throw new Error(
      'useCheckoutFlow should be used within CheckoutFlowContext',
    );

  return context;
}

async function saveCheckoutData(checkout: ICheckoutModel) {
  const { teddlyApi } = await getTeddlySdkApi();
  await teddlyApi.checkout.getCheckout(true, 'default-channel', checkout);
}

export function useInitCheckout() {
  const { state, setCompleted, setError, setLoading } =
    useNetworkState<ICheckoutModel>();

  const { variants: cartItems } = useCartContext();
  const {
    checkout,
    loaded,
    createCheckout,
  } = useCheckoutFlow();
  const { user } = useAuth();
  const userId = user ? user.id : null;

  useEffect(() => {
    if (!loaded || !userId) return;
    if (state.status === 'idle') {
      (async () => {
        setLoading();
        const funcToCall =
          checkout && checkout.id
            ? async () => {
                return cartItems?.length > 0
                  ? await updateCheckoutLines(
                      checkout.id,
                      cartItems.map((item) => ({
                        variantId: item.id,
                        quantity: item.line.quantity,
                        note: item.line.note,
                        orderId: item?.line?.order?.id,
                      })),
                    )
                  : null;
              }
            : async () => {
                const { data, dataError } = await createCheckout(user.email);
                return { data: data, errors: dataError };
              };

        try {
          const { data, errors } = await funcToCall();
          if (errors && errors.length) {
            setError(errors.map((e) => e.message).join('\n'));
          } else {
            setCompleted(data);
          }
        } catch (error) {
          setError(error);
        }
      })();
    }
  }, [userId, loaded, cartItems]);

  return state;
}

export type StripeAuthData = { apiKey: string };

export type AuthorizeNetAuthData = { apiLoginID: string; clientKey: string };

export type AuthData = StripeAuthData | AuthorizeNetAuthData;

const getConfigField = (config, field) => {
  return config.find((obj) => obj.field === field)?.value;
};

export function usePaymentProviderAuthData() {
  const { checkout, loaded } = useCheckoutFlow();
  const [authData, setAuthData] = useState<
    StripeAuthData | AuthorizeNetAuthData
  >(null);

  useEffect(() => {
    if (!loaded) return;
    if (!authData && checkout?.availablePaymentGateways) {
      const paymentGateway = getAvailablePaymentGateway(
        checkout?.availablePaymentGateways,
      );
      const config = checkout?.availablePaymentGateways[0]?.config || [];
      switch (paymentGateway) {
        case PaymentGatewayType.STRIPE:
          const apiKey = getConfigField(config, 'api_key');
          setAuthData({ apiKey });
          break;
        case PaymentGatewayType.AUTHORIZE:
          const apiLoginID = getConfigField(config, 'api_login_id');
          const clientKey = getConfigField(config, 'client_key');
          setAuthData({ apiLoginID, clientKey });
          break;
        default:
          break;
      }
    }
  }, [checkout, loaded]);

  return authData;
}

export function useSetShippingMethod(canContinue) {
  const { state, setLoading, setCompleted, setError } =
    useNetworkState<boolean>();
  const [isLoadedShipping, setIsLoadedShipping] = useState<boolean>(false);
  const [currentShippingId, setCurrentShippingId] = useState<string>();

  const { checkout, loaded, setShippingMethod } = useCheckoutFlow();
  const isShippingMethodAvailable = Boolean(
    checkout?.availableShippingMethods &&
      checkout?.availableShippingMethods[0]?.id,
  );

  // useEffect(() => {
  //   if (!canContinue || !isShippingMethodAvailable ) return;
  //   setLoading();
  //   setShippingMethod(checkout?.availableShippingMethods[0]?.id)
  //     .then(({ dataError, functionError, data }) => {
  //       if (dataError || functionError) {
  //         setError(
  //           dataError?.error ||
  //             functionError?.error ||
  //             'Something went wrong setting shipping method'
  //         );
  //       } else {
  //         console.info('shipping method set data', data);
  //         setCompleted(true);
  //       }
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //       setError(`Something went wrong in shipping method!`);
  //     });
  // }, [canContinue]);

  useEffect(() => {
    if (
      !checkout ||
      !checkout.id ||
      !loaded ||
      !canContinue ||
      !isShippingMethodAvailable
    ) {
      return;
    }
    //
    if (
      checkout.shippingMethod &&
      isLoadedShipping &&
      currentShippingId === checkout?.availableShippingMethods[0]?.id
    ) {
      setCompleted(true);
    } else {
      setLoading();
      setIsLoadedShipping(true);
      setCurrentShippingId(checkout?.availableShippingMethods[0]?.id);
      setShippingMethod(checkout?.availableShippingMethods[0]?.id)
        .then(({ dataError, functionError, data }) => {
          if (dataError || functionError) {
            setIsLoadedShipping(false);
            setCurrentShippingId('');

            setError(
              dataError?.error ||
                functionError?.error ||
                'Something went wrong setting shipping method',
            );
          } else {
            setCurrentShippingId(checkout?.availableShippingMethods[0]?.id);

            setCompleted(true);
            setIsLoadedShipping(true);
          }
        })
        .catch((e) => {
          console.error(e);
          setIsLoadedShipping(false);
          setCurrentShippingId('');
          setError(`Something went wrong  in shipping method!`);
        });
    }
  }, [canContinue, isShippingMethodAvailable]);

  return state;
}

export function useSetShippingAddress(
  canContinue,
  setShippingMethodStatus,
  shippingMethodError,
  currentSelectedAddress,
) {
  const { state, setLoading, setError, setCompleted } =
    useNetworkState<boolean>();

  const { user } = useAuth();
  const { loaded, checkout, setShippingAddress, setShippingMethod } =
    useCheckoutFlow();

  const lastAddressId = useRef(currentSelectedAddress?.id || 'none');
  const currentAddressId = currentSelectedAddress?.id;
  const isAddressChanged = currentAddressId !== lastAddressId.current;

  useEffect(() => {
    if (!loaded || !user || !user.id || !canContinue) return;
    if (currentAddressId && isAddressChanged && user?.email) {
      setLoading();
      setShippingMethodStatus('loading');

      const shippingAddress: ICheckoutAddress = {
        city: currentSelectedAddress?.city,
        country: {
          code: CountryCode.US,
          country: 'United States of America',
        },
        companyName: currentSelectedAddress?.companyName,
        countryArea: currentSelectedAddress?.countryArea,
        firstName: currentSelectedAddress?.firstName,
        id: currentSelectedAddress?.id,
        lastName: currentSelectedAddress?.lastName,
        phone: currentSelectedAddress?.phone,
        postalCode: currentSelectedAddress?.postalCode,
        streetAddress1: currentSelectedAddress?.streetAddress1,
        streetAddress2: currentSelectedAddress?.streetAddress2,
        note: currentSelectedAddress?.note,
        tag: currentSelectedAddress?.tag,
      };
      setShippingAddress(shippingAddress, user.email)
        .then(async ({ dataError, functionError, data }) => {
          lastAddressId.current = currentAddressId;
          if (dataError || functionError) {
            setError(
              dataError?.error ||
                functionError?.error ||
                'Something went wrong while saving the shipping address',
            );
          } else {
            if (
              data?.availableShippingMethods &&
              data?.availableShippingMethods[0]?.id
            ) {
              setShippingMethod(data?.availableShippingMethods[0]?.id)
                .then(({ dataError, functionError, data }) => {
                  if (dataError || functionError) {
                    setError(
                      dataError?.error ||
                        functionError?.error ||
                        'Something went wrong setting shipping method',
                    );
                    shippingMethodError(
                      dataError?.error ||
                        functionError?.error ||
                        'Something went wrong setting shipping method',
                    );
                  } else {
                    setCompleted(data);
                    setShippingMethodStatus('completed');
                  }
                })
                .catch((e) => {
                  console.error(e);
                  setShippingMethodStatus('error');
                  shippingMethodError(e.toString());
                  setError(`Something went wrong  in shipping method!`);
                });
            }
          }
        })
        .catch((e) => {
          console.error('console.error in catch', e);
          setError(e);
        });
    } else if (checkout.selectedShippingAddressId) {
      setCompleted(true);
    }
  }, [canContinue, currentSelectedAddress, isAddressChanged, loaded, user]);

  return state;
}

export function useSetBillingAddress(currentSelectedAddress: any) {
  const { state, setLoading, setCompleted, setError } = useNetworkState();
  const { user } = useAuth();
  const { loaded, checkout, setBillingAddress } = useCheckoutFlow();

  useEffect(() => {
    if (!loaded || !user || !user.id || !currentSelectedAddress) return;

    const currentAddressId = currentSelectedAddress?.id;
    const isAddressChanged = currentAddressId
      ? currentAddressId !== checkout?.billingAddress?.id
      : false;

    if (currentAddressId && isAddressChanged) {
      setLoading();
      setBillingAddress(
        {
          channel: currentSelectedAddress?.channel?.id,
          city: currentSelectedAddress?.city,
          country: {
            code: CountryCode.US,
            country: 'United States of America',
          },
          companyName: currentSelectedAddress?.companyName,
          countryArea: 'NY',
          firstName: currentSelectedAddress?.firstName,
          id: currentSelectedAddress?.id,
          lastName: currentSelectedAddress?.lastName,
          phone: currentSelectedAddress?.phone,
          postalCode: currentSelectedAddress?.postalCode,
          streetAddress1: currentSelectedAddress?.streetAddress1,
          streetAddress2: currentSelectedAddress?.streetAddress2,
          tag: currentSelectedAddress?.tag,
        },
        user.email,
      )
        .then(({ dataError, functionError }) => {
          if (dataError || functionError) {
            setError(
              dataError.error ||
                functionError?.error ||
                'Something went wrong setting billing address',
            );
          } else {
            setCompleted(true);
          }
        })
        .catch((e) => {
          console.error('catched error', e);
          setError(e);
        });
    }
  }, [currentSelectedAddress]);

  return state;
}

export function useCreatePaymentFromSource(
  canContinue: boolean,
  source: PaymentSource,
) {
  const { state, setCompleted, setError, setLoading } = useNetworkState();

  // useEffect(() => {
  //   if (!loaded || !canContinue) return;

  //   if (source && checkout?.shippingMethod?.id) {
  //     (async () => {
  //       setLoading();

  //       if (checkout && checkout.availablePaymentGateways) {
  //         console.info('useCreatePaymentFromSource', checkout);

  //         const gatewayId = checkout?.availablePaymentGateways[0]?.id;
  //         const { data, dataError, functionError } = await createPayment({
  //           gateway: gatewayId,
  //           returnUrl: `${window.location.origin}/checkout/payment-confirm`,
  //         });

  //         if (dataError || functionError) {
  //           setError(
  //             dataError?.error ||
  //               functionError?.error ||
  //               'Something went wrong creating payment with available source!'
  //           );
  //         } else {
  //           console.info('payment created data', data);
  //           setCompleted(data);
  //         }
  //       }
  //     })();
  //   }
  // }, [
  //   source,
  //   loaded,
  //   canContinue,
  //   checkout?.totalPrice?.gross?.amount,
  //   checkout?.shippingAddress?.id,
  // ]);

  return state;
}
